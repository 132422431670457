<template>
  <div class="main-panel">
    <el-button type="danger" size="mini" round class="save update-btn" @click="save">保存</el-button>
    <el-form :model="form" :rules="formRules" ref="formForm" @submit.prevent>
      <el-row :gutter="30">
        <el-col :span="12">
          <el-form-item label="胚布名" prop="product">
            <el-select :size="controlSize" filterable v-model="form.product" placeholder="请选择胚布" value-key="id">
              <el-option v-for="item in $parent.$parent.products" :key="item.id" :label="item.fabricName" :value="item" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="批次" prop="batch">
            <el-input v-model="form.batch" :size="controlSize" placeholder="批次" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="12">
          <el-form-item label="数量" prop="number">
            <el-input type="number" v-model.number="form.number" :size="controlSize" placeholder="数量" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="条数" prop="stripsNumber">
            <el-input type="number" v-model.number="form.stripsNumber" :size="controlSize" placeholder="条数" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="12">
          <el-form-item label="单位" prop="unit">
            <el-select :size="controlSize" filterable v-model="form.unit" placeholder="请选择单位" value-key="id">
              <el-option v-for="item in $parent.$parent.units" :key="item.id" :label="item.name" :value="item" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import _ from "lodash"
import validatorRules from "@/views/stock/incoming-order/validatorRules";

export default {
  props: {
    layerInfo: Object
  },

  data() {
    return {
      controlSize: "mini",
      form: {},
      formRules: {
        product: [{required: true, validator: validatorRules.validateProduct, trigger: "change"}],
        batch: [{required: true, message: "请填写批次", trigger: "blur"}],
        number: [{required: true, message: "请填写数量", trigger: "blur"}],
        stripsNumber: [{required: true, message: "请填写条数", trigger: "blur"}],
        unit: [{required: true, validator: validatorRules.validateUnit, trigger: "change"}]
      }
    }
  },

  mounted() {
    this.form = _.cloneDeep(this.layerInfo)
    Reflect.deleteProperty(this.form, "layerTitle")
  },

  methods: {
    save() {
      this.$emit("handle", this.form)
    }
  }
}
</script>
